import { canUseDOM, getWindow } from '@surfline/web-common';
import { useEffect, useState } from 'react';
import { useTimeoutFn, useUnmount } from 'react-use';

const useVideoJS = () => {
  const win = getWindow();
  const [videoJsLoaded, setVideoJsLoaded] = useState(() => {
    // If the player was already loaded in a previous mount, return true
    if (canUseDOM) {
      return !!win?.videojs;
    }
    return false;
  });

  const [videoJsError, setVideoJsError] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/naming-convention
  const [_isReady, cancel] = useTimeoutFn(
    () => setVideoJsError('Video.js loading timed out'),
    10000,
  );

  useEffect(() => {
    if (videoJsLoaded || videoJsError) {
      cancel();
    }
  }, [videoJsLoaded, videoJsError, cancel]);

  // Cancel the timer when we unmount
  useUnmount(() => {
    cancel();
  });

  return {
    videoJsLoaded,
    videoJsError,
    setVideoJsLoaded,
    setVideoJsError,
  };
};

export default useVideoJS;
