import React from 'react';
import PropTypes from 'prop-types';

import CamOverlay from './CamOverlay';

const CamUpsell = ({ stillUrl, href, onClick, text, cssModules, shouldLoad }) => (
  <div className={cssModules['quiver-cam-upsell'] || 'quiver-cam-upsell'}>
    {stillUrl && <CamOverlay backgroundImage={`url(${stillUrl})`} shouldLoad={shouldLoad} />}
    <div className="quiver-cam-upsell__message">
      <div className="quiver-cam-upsell__message__header">{text.primary}</div>
      <a href={href} onClick={onClick}>
        <button type="button">{text.link}</button>
      </a>
      <div
        className={
          cssModules['quiver-cam-upsell__message__subtext'] || 'quiver-cam-upsell__message__subtext'
        }
        dangerouslySetInnerHTML={{ __html: text.secondary }}
      />
    </div>
  </div>
);

CamUpsell.propTypes = {
  stillUrl: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.shape({
    primary: PropTypes.string,
    link: PropTypes.string,
    secondary: PropTypes.string,
  }),
  cssModules: PropTypes.shape(),
  shouldLoad: PropTypes.bool,
};

CamUpsell.defaultProps = {
  stillUrl: null,
  href: null,
  onClick: null,
  text: {
    primary: 'Watch waves, not ads. Start your free trial now.',
    link: 'START FREE TRIAL',
    secondary: `To watch for 30 more seconds,${' '}
    <a href="#" onClick="window.location.reload(true)">refresh the camera</a>.`,
  },
  cssModules: {},
  shouldLoad: false,
};

export default CamUpsell;
