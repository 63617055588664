import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { getWindow } from '@surfline/web-common';
import config from 'config';
import { isFinite, random } from 'lodash';

const AD_INTERVAL_SECONDS = 20;
const UPSELL_DURATION_SECONDS = 5;
const MAX_AD_ERRORS_ALLOWED = 5;

const AD_FALLBACK_SOURCES = [
  {
    poster: `${config.cdnUrl}/kbyg/videos/thumbnails/preroll_surfline_house_sick_sep23.jpg`,
    src: `${config.cdnUrl}/kbyg/videos/preroll_surfline_house_sick_sep23.mp4`,
  },
  {
    poster: `${config.cdnUrl}/kbyg/videos/thumbnails/preroll_surfline_rewind_sep23.jpg`,
    src: `${config.cdnUrl}/kbyg/videos/preroll_surfline_rewind_sep23.mp4`,
  },
];

/**
 * @param {object} props
 * @property {string} [adSettings.adVastTagUrl]
 * @property {React.ReactNode} [adSettings.MidrollCTA]
 * @param {?import('video.js').VideoJsPlayer} props.camPlayer
 * @param {boolean} props.forceBuiltInFallbackAd
 * @param {boolean} props.isPersistentCam
 * @param {boolean} props.hasAdFreeCamPermissions
 * @param {string} props.stillUrl
 * @param {string} props.streamUrl
 */
export const useCamPlayerAds = ({
  adSettings,
  camPlayer,
  forceBuiltInFallbackAd,
  isPersistentCam,
  hasAdFreeCamPermissions,
  pausePlayer,
  resumePlayer,
  setShowTimeout,
  stillUrl,
  streamUrl,
}) => {
  const [adRunning, setAdRunning] = useState(false);
  const [fallbackAdCountdownTime, setFallbackAdCountdownTime] = useState(0);
  const [nextAdCountdownTime, setNextAdCountdownTime] = useState(0);
  const [showUpsell, setShowUpsell] = useState(false);

  const adErrorCounter = useRef(1);
  const isFallbackAdRunning = useRef(false);
  const skipAdsNextPlay = useRef(false);
  const timeElapsedSeconds = useRef(0);
  const currentTime = useRef(0);

  const shouldPlayFallbackAd = useCallback(
    (errorCount) =>
      forceBuiltInFallbackAd || errorCount >= MAX_AD_ERRORS_ALLOWED || !adSettings?.adVastTagUrl,
    [adSettings, forceBuiltInFallbackAd],
  );

  const skipAds = useMemo(
    () =>
      !adSettings ||
      adSettings?.adVastTagUrl === null ||
      hasAdFreeCamPermissions ||
      isPersistentCam,
    [adSettings, isPersistentCam, hasAdFreeCamPermissions],
  );

  const checkIfAdIsRunning = useCallback(
    (player) =>
      adRunning ||
      isFallbackAdRunning.current === true ||
      player?.ads?.isInAdMode?.() ||
      player?.ads?.inAdBreak?.() ||
      false,
    [adRunning],
  );

  const showMidrollCTA = useCallback(
    (onMidrollComplete = () => {}) => {
      setShowUpsell(true);
      adSettings?.onToggleMidRoll?.(true);

      setTimeout(() => {
        onMidrollComplete();
        setShowUpsell(false);
        adSettings?.onToggleMidRoll?.(false);
      }, (adSettings.upsellDuration || UPSELL_DURATION_SECONDS) * 1000);
    },
    [adSettings],
  );

  const getPlayerSource = useCallback(
    (isFallbackAd) => {
      const randomAdIndex = random(1, false);
      return {
        poster: isFallbackAd ? AD_FALLBACK_SOURCES[randomAdIndex].poster : stillUrl,
        sources: [
          {
            src: isFallbackAd ? AD_FALLBACK_SOURCES[randomAdIndex].src : streamUrl,
            type: isFallbackAd ? 'video/mp4' : 'application/x-mpegURL',
          },
        ],
      };
    },
    [stillUrl, streamUrl],
  );

  const onFallbackAdComplete = useCallback(() => {
    if (isFallbackAdRunning.current === true) {
      pausePlayer();
      const streamSource = getPlayerSource(false);
      camPlayer.autoplay(false);
      camPlayer.poster(streamSource.poster);
      camPlayer.src(streamSource.sources);
      setAdRunning(false);
      isFallbackAdRunning.current = false;
      skipAdsNextPlay.current = true;
      resumePlayer();
    }
  }, [camPlayer, getPlayerSource, pausePlayer, resumePlayer]);

  const playAd = useCallback(() => {
    if (skipAds) return;
    setNextAdCountdownTime(0);
    setAdRunning(true);
    if (shouldPlayFallbackAd(adErrorCounter.current)) {
      isFallbackAdRunning.current = true;
      skipAdsNextPlay.current = true;
      const preroll = getPlayerSource(true);
      camPlayer.poster(preroll.poster);
      camPlayer.src(preroll.sources);
      resumePlayer();
    } else if (camPlayer) {
      camPlayer.ima.initializeAdDisplayContainer();
      camPlayer.ima.changeAdTag(adSettings.adVastTagUrl);
      camPlayer.ima.requestAds();
    }
  }, [adSettings, camPlayer, getPlayerSource, resumePlayer, shouldPlayFallbackAd, skipAds]);

  const playMidrollAds = useCallback(() => {
    if (camPlayer.isFullscreen()) {
      // Full screen mode to skip the in-house midroll CTA as it won't appear
      // when in full screen mode. Instead, just play the next ad.
      pausePlayer();
      playAd();
    } else {
      pausePlayer();
      showMidrollCTA(() => {
        playAd();
      });
    }
  }, [camPlayer, pausePlayer, playAd, showMidrollCTA]);

  const onPlay = useCallback(
    (event) => {
      const isAdRunning = checkIfAdIsRunning(camPlayer);
      if (skipAds) {
        return;
      }

      if (skipAdsNextPlay.current === true || isAdRunning) {
        skipAdsNextPlay.current = false;
        return;
      }

      event.preventDefault();
      pausePlayer();
      timeElapsedSeconds.current = 0;
      playAd();
      adSettings?.onTogglePreRoll?.(true);
    },
    [adSettings, camPlayer, checkIfAdIsRunning, pausePlayer, playAd, skipAds],
  );

  const onAdsError = useCallback(() => {
    if (adErrorCounter.current < MAX_AD_ERRORS_ALLOWED) {
      playAd();
    } else if (
      shouldPlayFallbackAd(adErrorCounter.current) &&
      adErrorCounter.current === MAX_AD_ERRORS_ALLOWED
    ) {
      // after max errors are hit, try to play the ad one more time which will be the fallback ad.
      playAd();
    }

    adErrorCounter.current += 1;
  }, [playAd, shouldPlayFallbackAd]);

  const onAdStarted = useCallback(() => {
    adSettings?.onAdStarted?.();
  }, [adSettings]);

  const onAllAdsComplete = useCallback(() => {
    adErrorCounter.current = 1;
    skipAdsNextPlay.current = true;
    timeElapsedSeconds.current = 0;
    setNextAdCountdownTime(AD_INTERVAL_SECONDS);
    setShowTimeout(false);
    setAdRunning(false);
    resumePlayer();
    adSettings?.onTogglePreRoll?.(false);
  }, [adSettings, resumePlayer, setShowTimeout]);

  const onAdsManagerLoaded = useCallback(() => {
    // Google is a global object loaded by the script and we don't define this variable
    // eslint-disable-next-line no-undef
    camPlayer.ima.addEventListener(google.ima.AdEvent.Type.STARTED, onAdStarted);
    // eslint-disable-next-line no-undef
    camPlayer.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, onAllAdsComplete);
  }, [camPlayer?.ima, onAdStarted, onAllAdsComplete]);

  const onCamPlayerClick = (event) => {
    if (isFallbackAdRunning.current === true && event?.target?.tagName === 'VIDEO') {
      const win = getWindow();
      win.location.assign(`${config.surflineHost}${config.funnelUrl}`);
    }
  };

  const onTimeUpdate = useCallback(() => {
    const isAdRunning = checkIfAdIsRunning(camPlayer);
    if (skipAds || isAdRunning || camPlayer?.ads?.isContentResuming?.()) {
      timeElapsedSeconds.current = 0;

      if (isFallbackAdRunning.current === true && isFinite(camPlayer.duration())) {
        setFallbackAdCountdownTime(Math.round(camPlayer.duration() - camPlayer.currentTime()));
      }
      return;
    }

    /*
      Because this Event is fired multiple times (<=10) a second,
      this conditional is required for tracking total seconds played.
    */

    const playerPosition = Math.round(camPlayer.currentTime());
    if (playerPosition !== currentTime.current) {
      currentTime.current = playerPosition;
      timeElapsedSeconds.current += 1;
    }

    if (timeElapsedSeconds.current === AD_INTERVAL_SECONDS) {
      timeElapsedSeconds.current = 0;
      setNextAdCountdownTime(0);
      playMidrollAds();
    } else {
      setNextAdCountdownTime(AD_INTERVAL_SECONDS - timeElapsedSeconds.current);
    }
  }, [camPlayer, checkIfAdIsRunning, playMidrollAds, skipAds]);

  useEffect(() => {
    adErrorCounter.current = 1;
    timeElapsedSeconds.current = 0;
    currentTime.current = 0;

    if (camPlayer) {
      if (hasAdFreeCamPermissions) {
        return;
      }

      if (!shouldPlayFallbackAd(adErrorCounter.current)) {
        camPlayer.ima({
          adsManagerLoadedCallback: onAdsManagerLoaded,
          autoPlayAdBreak: false,
          contribAdsSettings: {
            contentIsLive: true,
          },
          debug: true, // enable for verbose logging from the video.js ads plugin
          disableAdControls: false,
        });
      }

      const clickEventName = 'ontouchstart' in getWindow() ? 'touchend' : 'click';
      camPlayer.on('adserror', onAdsError);
      camPlayer.on('adtimeout', onAdsError);
      camPlayer.on(clickEventName, onCamPlayerClick);
      camPlayer.on('ended', onFallbackAdComplete);
      camPlayer.on('play', onPlay);
      camPlayer.on('timeupdate', onTimeUpdate);

      camPlayer.one('dispose', () => {
        try {
          camPlayer?.off('adserror', onAdsError);
          camPlayer?.off('adtimeout', onAdsError);
          camPlayer?.off(clickEventName, onPlay);
          camPlayer?.off('ended', onFallbackAdComplete);
          camPlayer?.off('play', onPlay);
          camPlayer?.off('timeupdate', onTimeUpdate);
        } catch (error) {
          console?.error(error);
        }
      });
    }

    // We only want to renew these hooks when we have a new cam player
    // since it will add a new callback to the cam player every single time
    // `on()` is called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camPlayer]);

  return {
    adRunning,
    fallbackAdCountdownTime,
    nextAdCountdownTime,
    showUpsell,
    upsellDuration: adSettings.upsellDuration || UPSELL_DURATION_SECONDS,
  };
};
