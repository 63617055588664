import { SL_PREMIUM_PLUS } from 'common/treatments';
import type { Camera } from 'types/camera';
import { useTreatments } from 'utils/treatments';
import { SpotDetails } from 'types/spot';

const useCheckCameraSupportsSmartRewinds = (camera: Camera, spot: SpotDetails) => {
  const treatments = useTreatments();
  return (
    !!camera.supportsSmartRewinds &&
    !!spot?.insightsCameraId &&
    treatments?.[SL_PREMIUM_PLUS] === 'on'
  );
};

export default useCheckCameraSupportsSmartRewinds;
