import { useEffect, useState } from 'react';
import useIsActivePage from './useIsActivePage';

const useIsVisible = (element: HTMLDivElement | null) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const isActivePage = useIsActivePage();

  useEffect(() => {
    if (!element) return () => {};

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return isIntersecting && isActivePage;
};

export default useIsVisible;
