import React from 'react';
import PropTypes from 'prop-types';

import styles from './CamPlaceholder.module.scss';

const CamPlaceholder = ({ isDown, shouldLoad, stillUrl }) => (
  <div
    className={styles.CamPlaceholder}
    style={
      shouldLoad
        ? {
            backgroundImage: `url(${stillUrl})`,
          }
        : {}
    }
  >
    {!isDown && (
      <div className={styles.spinnerContainer}>
        <div className={styles.loading} />
      </div>
    )}
  </div>
);

CamPlaceholder.propTypes = {
  stillUrl: PropTypes.string,
  shouldLoad: PropTypes.bool,
};

CamPlaceholder.defaultProps = {
  stillUrl: '',
  shouldLoad: false,
};

export default CamPlaceholder;
