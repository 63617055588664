import PropTypes from 'prop-types';

/**
 * @typedef {object} CameraStatus
 * @property {string} altMessage
 * @property {boolean} isDown
 * @property {string} message
 * @property {string} subMessage
 */

/**
 * @typedef {import('../../../types/camera').Camera} Camera
 */

export const cameraPropType = PropTypes.shape({
  streamUrl: PropTypes.string,
  stillUrl: PropTypes.string,
  status: PropTypes.shape(),
  isPrerecorded: PropTypes.bool,
  isPremium: PropTypes.bool,
  title: PropTypes.string,
});

export const camerasPropType = PropTypes.arrayOf(cameraPropType);
