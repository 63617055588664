import { memo } from 'react';

const PauseButton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.5" width="100" height="100" rx="50" fill="#171717" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.75 36.25C41.6789 36.25 40 37.9289 40 40V60C40 62.0711 41.6789 63.75 43.75 63.75C45.8211 63.75 47.5 62.0711 47.5 60V40C47.5 37.9289 45.8211 36.25 43.75 36.25ZM56.25 36.25C54.1789 36.25 52.5 37.9289 52.5 40V60C52.5 62.0711 54.1789 63.75 56.25 63.75C58.3211 63.75 60 62.0711 60 60V40C60 37.9289 58.3211 36.25 56.25 36.25Z"
      fill="white"
    />
  </svg>
);

export default memo(PauseButton);
