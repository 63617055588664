import React from 'react';
import PropTypes from 'prop-types';

const AspectRatioContent = ({ children }) => (
  <div className="quiver-aspect-ratio-content">{children}</div>
);

AspectRatioContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AspectRatioContent;
