import { useEffect } from 'react';
import { AutoplayOptions } from './constants';

/**
 * @param {?import('./').CamPlayer} camPlayer
 * @param {import('./CamPlayerV2').CamPlayerProps['handleCamReady']} handleCamReady
 * @param {import('./CamPlayerV2').CamPlayerProps['setCamReady']} setCamReady
 * @param {import('./CamPlayerV2').CamPlayerProps['camReady']} camReady
 * @param {import('./CamPlayerV2').CamPlayerProps['autoplay']} autoplay
 * @param {import('./CamPlayerV2').CamPlayerProps['isPremium']} isPremium
 * @param {boolean} isDown
 */
export const useCamReady = (
  camPlayer,
  handleCamReady,
  setCamReady,
  camReady,
  autoplay,
  isPermitted,
  isDown,
) => {
  useEffect(() => {
    const handleError = () => {
      if (handleCamReady && camReady !== true) {
        setCamReady(true);
      }
    };

    const handleLoadedMeta = () => {
      if (handleCamReady && camReady !== true) {
        setCamReady(true);
      }
    };

    const handleCanPlay = () => {
      const noAutoplay = autoplay === AutoplayOptions.AUTOPLAY_DISABLED;
      if ((!isPermitted || noAutoplay) && handleCamReady && !camReady) {
        setCamReady(true);
      }
    };

    camPlayer?.on('error', handleError);
    camPlayer?.on('loadedmetadata', handleLoadedMeta);
    camPlayer?.on('canplay', handleCanPlay);
    camPlayer?.one('dispose', () => {
      camPlayer?.off('error', handleError);
      camPlayer?.off('loadedmetadata', handleLoadedMeta);
      camPlayer?.off('canplay', handleCanPlay);
      if (handleCamReady) {
        setCamReady(false);
      }
    });
  }, [camPlayer, handleCamReady, setCamReady, camReady, isPermitted, autoplay]);

  useEffect(() => {
    if (isDown && handleCamReady && camReady !== true) {
      setCamReady(true);
    }
  }, [isDown, handleCamReady, setCamReady, camReady]);
};
