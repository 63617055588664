/** @prettier */
/* global window */
import { useEffect } from 'react';

/**
 * @description Given a memoized callback and an event, will call the callback
 * everytime the event is detected.
 *
 * @param {Function} memoizedCb Memoized version of a callback to use with
 * the event listener (memoized to prevent extra triggers which would add many event listeners)
 * @param {String} type Event type to listen for (https://developer.mozilla.org/en-US/docs/Web/Events)
 */
export const useEventListener = (memoizedCb, type, customDocument = false) => {
  useEffect(() => {
    const customTarget = customDocument || window;
    customTarget.addEventListener(type, memoizedCb);
    return () => {
      customTarget.removeEventListener(type, memoizedCb);
    };
  }, [type, memoizedCb, customDocument]);
};
