import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = { currentCount: props.countTime };
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  timer() {
    const { onCountdownComplete } = this.props;
    this.setState((prevState) => ({
      currentCount: prevState.currentCount - 1,
    }));

    const { currentCount } = this.state;
    if (currentCount < 1) {
      clearInterval(this.intervalId);
      onCountdownComplete();
    }
  }

  render() {
    const { currentCount } = this.state;
    return <span>{currentCount}</span>;
  }
}

CountDownTimer.defaultProps = {
  countTime: 0,
  onCountdownComplete: () => {},
};

CountDownTimer.propTypes = {
  countTime: PropTypes.number,
  onCountdownComplete: PropTypes.func,
};

export default CountDownTimer;
