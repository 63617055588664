import { useState, useRef, useCallback } from 'react';
import { isNil } from 'lodash';
import { Cookies } from 'react-cookie';
import { useUnmount } from 'react-use';
import { getWindow } from '@surfline/web-common';
import type { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import type { Camera } from 'types/camera';
import { AutoplayOptions, PlayerSkins } from './constants';
import { addCamRewindButton } from './utils';

const window = getWindow();

export interface UseCamPlayerProps {
  aspectRatio: string;
  autoplay: AutoplayOptions;
  camera: Camera;
  isHighlights?: boolean;
  isPersistentCam: boolean;
  isPermitted: boolean;
  isPrerecorded: boolean;
  playbackRateControls: boolean;
  playerId: string;
  playerSkin: PlayerSkins;
  rewindUrl: string | null;
  shouldLoad: boolean;
  spotId: string;
  spotName: string;
  videoElement?: HTMLDivElement;
}

export const useCamPlayer = ({
  aspectRatio,
  autoplay,
  camera,
  isHighlights = false,
  isPersistentCam,
  isPermitted,
  isPrerecorded,
  playbackRateControls,
  playerId,
  playerSkin,
  rewindUrl,
  shouldLoad,
  spotId,
  spotName,
  videoElement: videoRef,
}: UseCamPlayerProps) => {
  const [camPlayer, setCamPlayer] = useState<VideoJsPlayer | null>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const setupPlayer = useCallback(() => {
    const streamUrl = !isHighlights ? camera?.streamUrl : camera?.highlights?.url;

    if (!window?.videojs || isNil(streamUrl) || !shouldLoad) return null;

    const shouldAddRewindButton = !isPrerecorded && !isNil(rewindUrl);
    const cookie = new Cookies();
    const accessToken = cookie.get('access_token');
    const file = accessToken ? `${streamUrl}?accessToken=${accessToken}` : streamUrl;
    const shouldAutoStart = autoplay === AutoplayOptions.AUTOPLAY_ENABLED || isPersistentCam;
    const isMp4 = file.indexOf('mp4') > -1;
    const sources = [
      {
        src: streamUrl,
        type: isMp4 ? 'video/mp4' : 'application/x-mpegURL',
      },
    ];
    const playerOptions: VideoJsPlayerOptions = {
      aspectRatio,
      autoplay: shouldAutoStart,
      controls: !(playerSkin === PlayerSkins.NONE || playerSkin === PlayerSkins.THUMBNAIL),
      disablePictureInPicture: false,
      inactivityTimeout: 4000,
      muted: shouldAutoStart, // browsers prevents un-muted cams from auto-play
      playbackRates: playbackRateControls ? [0.5, 1, 1.25, 1.5, 2, 4, 8] : undefined,
      playsinline: true,
      plugins: {
        hotkeys: {
          enableMute: false,
          enableVolumeScroll: false,
        },
      },
      poster: camera.stillUrl,
      preload: 'auto',
      sources,
    };

    let player: VideoJsPlayer;

    if (!playerRef.current || videoRef?.childElementCount === 0) {
      const videoElement = window.document.createElement('video-js');
      videoElement.setAttribute('id', playerId);
      videoRef?.appendChild(videoElement);
      if (playerSkin === PlayerSkins.MINIMAL) {
        videoElement.classList.add('vjs-sl-cam-player', 'vjs-sl-cam-player-minimal');
      } else {
        videoElement.classList.add('vjs-sl-cam-player');
      }
      if (shouldAutoStart) {
        videoElement.classList.add('vjs-sl-cam-player-autoplay');
      }
      player = window.videojs(videoElement, playerOptions);
      setCamPlayer(player);
      playerRef.current = player;
    } else {
      player = playerRef.current;
      if (playerSkin === PlayerSkins.MINIMAL) {
        player.addClass('vjs-sl-cam-player-minimal');
      } else {
        player.removeClass('vjs-sl-cam-player-minimal');
      }
      if (shouldAutoStart) {
        player.addClass('vjs-sl-cam-player-autoplay');
      } else {
        player.removeClass('vjs-sl-cam-player-autoplay');
      }
      if (playerSkin === PlayerSkins.NONE || playerSkin === PlayerSkins.THUMBNAIL) {
        player.controls(false);
      } else {
        player.controls(true);
      }
      if (!isNil(playerOptions?.poster)) player.poster(playerOptions.poster);
      if (!isNil(playerOptions?.autoplay)) player.autoplay(playerOptions.autoplay);
      if (!isNil(playerOptions?.sources)) player.src(playerOptions.sources);
      return player;
    }

    if (shouldAddRewindButton) {
      addCamRewindButton(
        player,
        spotId,
        spotName,
        camera._id,
        camera.title,
        isPermitted,
        rewindUrl,
        camera.isPremium,
      );
    }

    return player;
  }, [
    aspectRatio,
    autoplay,
    camera._id,
    camera.isPremium,
    camera.stillUrl,
    camera.streamUrl,
    camera.title,
    camera?.highlights?.url,
    isHighlights,
    isPersistentCam,
    isPermitted,
    isPrerecorded,
    playbackRateControls,
    playerId,
    playerSkin,
    rewindUrl,
    shouldLoad,
    spotId,
    spotName,
    videoRef,
  ]);

  useUnmount(() => {
    const player = playerRef?.current;
    if (player && !player.isDisposed()) {
      try {
        player.dispose();
        playerRef.current = null;
      } catch (error) {
        console?.error(error);
      }
    }
  });

  return {
    camPlayer,
    setupPlayer,
  };
};

export default useCamPlayer;
