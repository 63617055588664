export enum AutoplayOptions {
  AUTOPLAY_DISABLED = 'disabled',
  AUTOPLAY_ENABLED = 'enabled',
}

export enum PlayerSkins {
  DEFAULT = 'default',
  MINIMAL = 'minimal',
  THUMBNAIL = 'thumbnail',
  NONE = 'none',
}
