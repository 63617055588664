import { memo } from 'react';

const PlayButton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.5" width="100" height="100" rx="50" fill="#171717" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.8749 49.1778C63.4493 49.5755 63.4493 50.4245 62.8749 50.8222L45.3192 62.9761C44.656 63.4353 43.75 62.9606 43.75 62.1539V37.8461C43.75 37.0394 44.656 36.5647 45.3192 37.0239L62.8749 49.1778Z"
      fill="white"
    />
  </svg>
);

export default memo(PlayButton);
