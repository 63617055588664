import { trackEvent } from '@surfline/web-common';
import { useEffect, useCallback, useMemo, useRef } from 'react';
import { useUnmount } from 'react-use';
import type { Camera } from 'types/camera';

export const PLAYED_WEBCAM = 'Played Webcam';
export const STOPPED_WEBCAM = 'Stopped Webcam';

type PlaybackEventProperties = {
  category: string;
  premiumCam: boolean;
  spotName: string;
  camName: string;
  camId: string;
  favorite: boolean;
  spotId: string;
  videoType: string;
  smartCam: boolean;
};

/**
 * @description Because we rely on the JWPlayer `.on()` hooks to fire the track event
 * we want to make sure that we are not subject to the details of how they implement
 * these hooks. To achieve this we can use a ref, and mutate the `.current` property
 * of it. This will prevent us from being at the mercy of whether or not calling `.on`
 * multiple times will actually update the callback correctly
 */
const usePlaybackEventProperties = (
  camera: Camera,
  location: string,
  spotName: string,
  spotId: string,
  isFavorite: boolean,
  videoType: string,
  smartCam?: boolean,
) => {
  const playbackEventProperties = useMemo<PlaybackEventProperties>(
    () => ({
      category: location,
      premiumCam: camera.isPremium,
      spotName,
      camName: camera.title,
      camId: camera._id,
      favorite: isFavorite,
      spotId,
      videoType,
      smartCam: smartCam || false,
    }),
    [location, camera, isFavorite, spotName, spotId, videoType, smartCam],
  );

  const eventPropertiesRef = useRef<PlaybackEventProperties>(playbackEventProperties);

  // Whenever the memoized properties update, we want to update the value inside the ref
  useEffect(() => {
    eventPropertiesRef.current = playbackEventProperties;
  }, [playbackEventProperties]);

  return eventPropertiesRef;
};

export const useTrackCamPlayback = (
  camera: Camera,
  location: string,
  spotName: string,
  spotId: string,
  isFavorite: boolean,
  videoType: string,
  dontTrack?: boolean,
  smartCam?: boolean,
) => {
  const playbackEventProperties = usePlaybackEventProperties(
    camera,
    location,
    spotName,
    spotId,
    isFavorite,
    videoType,
    smartCam,
  );

  const pauseTracked = useRef<boolean>(false);
  const playTracked = useRef<boolean>(false);

  const trackPause = useCallback(
    /**
     * @param {boolean} isTimeout
     */
    (isTimeout = false) => {
      if (dontTrack) return;
      if (pauseTracked.current === false && playTracked.current === true) {
        pauseTracked.current = true;
        trackEvent(STOPPED_WEBCAM, {
          ...playbackEventProperties.current,
          isTimeout,
        });
      }
    },
    [playbackEventProperties, dontTrack],
  );

  const trackPlay = useCallback(() => {
    if (dontTrack) return;
    if (playTracked.current === false) {
      playTracked.current = true;
      trackEvent(PLAYED_WEBCAM, playbackEventProperties.current);
    }
  }, [playbackEventProperties, dontTrack]);

  // Track play again if different camera angle is selected
  useEffect(() => {
    playTracked.current = false;
  }, [camera._id]);

  useUnmount(() => trackPause());

  return { trackPause, trackPlay };
};
