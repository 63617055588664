import { fetchPopularSpots } from 'common/api/spot';
import { doFetch } from 'common/baseFetch';
import type { UserFavoritesResponse } from 'types/userFavorites';
import { slugify } from '@surfline/web-common';
import { UserCustomLocationsResponse } from 'types/userCustomLocations';

export const getLatestRewind = async (alias: string) => {
  const endDate = new Date();
  const endTimestampInMs = endDate.getTime();
  const startTimestampInMs = endTimestampInMs - 30 * 60 * 1000; // 30 mins

  const data = (await doFetch(
    `/cameras/recording/${alias}?startDate=${startTimestampInMs}&endDate=${endTimestampInMs}`,
  )) as any;

  return data.length > 0 ? data[0].recordingUrl : null;
};

const favoritesFetch = async (): Promise<UserFavoritesResponse> => {
  const data = (await doFetch('/kbyg/favorites?')) as UserFavoritesResponse;
  return {
    data: {
      favorites: data.data.favorites,
    },
    associated: data.associated,
  };
};

export const getPopularSpots = async (countryCode?: string): Promise<UserFavoritesResponse> => {
  const { associated, data } = await fetchPopularSpots(countryCode);
  return {
    data: {
      favorites: data?.spots?.map((srv) => ({
        ...srv,
        subregionName: srv.subregion.name,
        slug: slugify(srv.subregion.name),
        wind: { ...srv.wind, directionType: '' },
      })),
    },
    associated,
  };
};

export const customLocationFetch = async (): Promise<UserCustomLocationsResponse> => {
  const data = (await doFetch('/favorites/locations?')) as UserCustomLocationsResponse;
  return {
    favorites: data.favorites,
  };
};

export default favoritesFetch;
