import { useEffect, useState } from 'react';
import { getLatestRewind } from 'utils/favoritesFetch';

const useLatestRewind = (alias: string, shouldUseLatestRewind: boolean, isVisible: boolean) => {
  const [latestRewind, setLatestRewind] = useState(null);

  useEffect(() => {
    if (shouldUseLatestRewind && isVisible && !latestRewind) {
      (async () => {
        setLatestRewind(await getLatestRewind(alias));
      })();
    }
  }, [alias, shouldUseLatestRewind, isVisible, latestRewind]);

  return latestRewind;
};

export default useLatestRewind;
