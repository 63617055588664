import { useEffect, useRef } from 'react';

/**
 * @description When the playback is controlled via the parent component
 * this hook will sync the player to the `isPlaying` prop. If that prop changes
 * the player will either play or pause
 * @param {?import('./').CamPlayer} camPlayer
 * @param {boolean} controlledPlayback
 * @param {boolean} isPlaying
 * @param {() => void} resumePlayer
 * @param {() => void} pausePlayer

 */
export const useControlledPlayback = (
  camPlayer,
  controlledPlayback,
  isPlaying,
  resumePlayer,
  pausePlayer,
) => {
  const playingRef = useRef(isPlaying);

  useEffect(() => {
    playingRef.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => {
    if (controlledPlayback) {
      if (isPlaying) {
        resumePlayer();
      } else {
        pausePlayer();
      }
    }
  }, [controlledPlayback, isPlaying, resumePlayer, pausePlayer]);

  useEffect(() => {
    camPlayer?.on('firstFrame', () => {
      if (controlledPlayback && !playingRef.current) {
        pausePlayer();
      }
    });
  }, [camPlayer, controlledPlayback, pausePlayer]);
};
