import React from 'react';
import { Box, Typography } from '@mui/material';
import productCDN from '@surfline/quiver-assets';
import classNames from 'classnames';

import RewindV2 from '../Icons/RewindV2';
import Styles from './CamError.module.scss';

interface Props {
  messageData: {
    message: string;
    subMessage: string;
  };
  rewindUrl?: string;
  camStillUrl?: string;
}

const CAM_DOWN_IMAGE_URL = `${productCDN}/backgrounds/cam-down-bg-image.png`;

const CamError: React.FC<Props> = ({
  messageData = {
    message: 'Failed to Load',
    subMessage: `Try reloading the cam.`,
  },
  rewindUrl = null,
  camStillUrl = CAM_DOWN_IMAGE_URL,
}) => (
  <Box className={Styles.camErrorWrapper} data-testid="cam-error-wrapper">
    <Box className={Styles.camErrorContainer}>
      <div
        className={classNames(Styles.camErrorBackgroundImage, 'percy-hide')}
        style={{ backgroundImage: `url(${camStillUrl || CAM_DOWN_IMAGE_URL})` }}
      />

      <Box className={Styles.camErrorContent}>
        <Box className={Styles.camErrorMessageWrapper}>
          <Typography variant="title3" className={Styles.camErrorText1}>
            {messageData.message}
          </Typography>
          <Typography variant="subHeadline" className={Styles.camErrorText2}>
            {messageData.subMessage}
          </Typography>
        </Box>
        {rewindUrl && (
          <a href={rewindUrl} className={Styles.camRewindLink}>
            <RewindV2 />
          </a>
        )}
      </Box>
    </Box>
  </Box>
);

export default CamError;
