import { memo } from 'react';

const FastForwardButton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.5" width="80" height="80" rx="40" fill="#171717" />
    <path
      d="M51.9359 28.2754C59.0044 34.8235 59.3911 45.8244 52.7996 52.8464C46.2081 59.8685 35.1345 60.2526 28.0661 53.7045C20.9976 47.1563 20.6109 36.1554 27.2023 29.1334C30.9516 25.1392 36.151 23.2927 41.2386 23.6479M38.751 19.625L43.2082 23.8472L38.751 28.375"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(FastForwardButton);
