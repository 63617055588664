import { getWindow } from '@surfline/web-common';
import { useMemo } from 'react';

const useIsTouchDevice = () => {
  const canHover = useMemo(() => {
    const win = getWindow();
    if (win) {
      return !!matchMedia('(pointer: coarse)').matches;
    }
    return false;
  }, []);

  return canHover;
};

export default useIsTouchDevice;
