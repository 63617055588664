import { trackEvent } from '@surfline/web-common';
import { useEffect, useCallback, useMemo, useRef } from 'react';

export const CLICKED_SUBSCRIBE_CTA = 'Clicked Subscribe CTA';

/**
 * @description A hook that will trigger a segment event anytime a CTA is clicked
 * within the cam player
 * @param {import('../PropTypes/cameras').Camera} camera
 * @param {string} location
 * @param {string} spotName
 * @param {string} spotId
 */
const useClickedSubscribeEventProperties = (camera, location, spotName, spotId) => {
  const clickedSubscribeEventProperties = useMemo(
    () => ({
      category: location,
      spotName,
      camId: camera._id,
      spotId,
    }),
    [location, camera, spotName, spotId],
  );

  const eventPropertiesRef = useRef(clickedSubscribeEventProperties);

  // Whenever the memoized properties update, we want to update the value inside the ref
  useEffect(() => {
    eventPropertiesRef.current = clickedSubscribeEventProperties;
  }, [clickedSubscribeEventProperties]);

  return eventPropertiesRef;
};

/**
 * @param {import('../PropTypes/cameras').Camera} camera
 * @param {string} location
 * @param {string} spotName
 * @param {string} spotId
 */
export const useTrackClickedSubscribe = (camera, location, spotName, spotId) => {
  const eventProperties = useClickedSubscribeEventProperties(camera, location, spotName, spotId);

  const trackClickedSubscribeCTA = useCallback(() => {
    trackEvent(CLICKED_SUBSCRIBE_CTA, eventProperties.current);
  }, [eventProperties]);

  return { trackClickedSubscribeCTA };
};
