import { useEffect, useState, useCallback, useMemo } from 'react';
import { isNil } from 'lodash';
import { nrNoticeError, trackEvent } from '@surfline/web-common';

import { useCamPlayer, UseCamPlayerProps } from './useCamPlayer';
import { useCamMetrics } from './useCamMetrics';
import { useCamPlayback } from './useCamPlayback';
import usePauseOffscreenPlayer from './usePauseOffscreenPlayer';
import { useTrackCamPlayback } from './useTrackCamPlayback';
import { useCamPlaybackControls } from './useCamPlaybackControls';

interface Props extends UseCamPlayerProps {
  dontTrack: boolean;
  isFavorite: boolean;
  isVisible: boolean;
  location: string;
  loop: boolean;
  pauseWhenNotVisible: boolean;
  segmentProperties: {
    [key: string]: string | number;
  };
}

export const useCamHighlights = ({
  aspectRatio,
  autoplay,
  camera,
  dontTrack,
  isFavorite,
  isPersistentCam,
  isPermitted,
  isPrerecorded,
  isVisible,
  location,
  loop,
  pauseWhenNotVisible,
  playbackRateControls,
  playerId,
  playerSkin,
  rewindUrl,
  segmentProperties,
  shouldLoad,
  spotId,
  spotName,
  videoElement: videoRef,
}: Props) => {
  const highlightsClipUrl = useMemo(() => camera?.highlights?.url, [camera]);
  const [showHighlights, setShowHighlights] = useState(!loop && !isNil(highlightsClipUrl));
  const [highlightsCountdownTime, setHighlightsCountdownTime] = useState(0);

  const { camPlayer, setupPlayer } = useCamPlayer({
    aspectRatio,
    autoplay,
    camera,
    isHighlights: true,
    isPersistentCam,
    isPermitted,
    isPrerecorded,
    playbackRateControls,
    playerId,
    playerSkin,
    rewindUrl,
    shouldLoad,
    spotId,
    spotName,
    videoElement: videoRef,
  });

  const { pausePlayer, restartPlayer, resumePlayer } = useCamPlaybackControls(camPlayer);

  useCamMetrics({
    camPlayer,
    camera,
    isHighlightsPlayer: true,
  });

  const onEnded = useCallback(async () => {
    if (loop) {
      restartPlayer();
    } else {
      setShowHighlights(false);
    }
  }, [loop, restartPlayer]);

  const onError = (error: Error) => {
    setShowHighlights(false);
    nrNoticeError(error, {});
  };

  const onTimeUpdate = useCallback(() => {
    setHighlightsCountdownTime(
      Math.floor((camPlayer?.duration() ?? 0) - (camPlayer?.currentTime() ?? 0)),
    );
  }, [camPlayer]);

  useEffect(() => {
    if (camPlayer && !isNil(highlightsClipUrl)) {
      camPlayer.on('ended', onEnded);
      camPlayer.on('error', onError);
      camPlayer.on('timeupdate', onTimeUpdate);
    } else if (camPlayer) {
      camPlayer.off('ended', onEnded);
      camPlayer.off('error', onError);
      camPlayer.off('timeupdate', onTimeUpdate);
    }
    return () => {
      camPlayer?.off('ended', onEnded);
      camPlayer?.off('error', onError);
      camPlayer?.off('timeupdate', onTimeUpdate);
    };
  }, [camPlayer, highlightsClipUrl, onEnded, onTimeUpdate]);

  useEffect(() => {
    if (showHighlights && camPlayer) {
      resumePlayer();
    } else if (!showHighlights && camPlayer) {
      pausePlayer();
    }
  }, [camPlayer, pausePlayer, resumePlayer, showHighlights]);

  const skipHighlights = useCallback(
    async (e) => {
      e?.stopPropagation();
      trackEvent('Clicked Toggle', {
        ...segmentProperties,
        toggleType: 'highlights',
        toggleStart: 'highlights',
        toggleEnd: 'live',
      });
      try {
        await camPlayer?.pause();
        await camPlayer?.currentTime(0);
      } catch (skipHighlightsException) {
        // play and pause can throw exceptions with play and pause that don't reflect actionable errors.
        // We'll silently catch them here to avoid having them bubble up to New Relic.
        console.log('skipHighlights exception=', skipHighlightsException);
      }
      setShowHighlights(false);
    },
    [segmentProperties, camPlayer],
  );

  const setShowTimeout = useCallback(() => {}, []);

  const isTrackingDisabled = useMemo(() => dontTrack, [dontTrack]);

  const { trackPlay, trackPause } = useTrackCamPlayback(
    camera,
    location,
    spotName,
    spotId,
    isFavorite,
    'highlights',
    isTrackingDisabled,
  );

  const isStreaming = useCamPlayback({
    camPlayer,
    setShowTimeout,
    setupPlayer,
    streamUrl: highlightsClipUrl || '',
    trackPause,
    trackPlay,
  });

  usePauseOffscreenPlayer({
    camPlayer,
    isRecording: true,
    isStreaming,
    isVisible,
    pauseWhenNotVisible,
    stopStream: !showHighlights,
  });

  return { showHighlights, setShowHighlights, camPlayer, skipHighlights, highlightsCountdownTime };
};

export default useCamHighlights;
