import { slugify, trackEvent, getWindow } from '@surfline/web-common';
import theme from '@wavetrak/theme';

const window = getWindow();

export const ALT = 'ALT';
export const SUB = 'SUB';
export const MSG = 'MSG';

/**
 * @param {import('./').CameraStatus} status
 * @param {boolean} isClip
 * @param {ALT | SUB | MSG} messageType
 * @param {import('./').NetworkError} networkError
 */
export const messageConfig = (status, isClip = false, messageType = null, networkError = {}) => {
  const showSubMessage = messageType === SUB;

  if (isClip) {
    return {
      camStatusMessage: {
        message: status.message,
        subMessage: null,
      },
      camDownMessage: {
        message: status.message,
        subMessage: null,
      },
    };
  }

  const messageData = {
    camStatusMessage: {
      message: messageType === ALT ? status.altMessage : status.message,
      subMessage: showSubMessage ? status.subMessage : null,
    },
    camDownMessage: {
      message: messageType === ALT ? networkError.altMessage : networkError.message,
      subMessage: showSubMessage ? networkError.subMessage : null,
    },
  };

  return messageData;
};

/**
 * @param {string} spotName
 * @param {import('./').Camera} camera
 * @param {string} location
 */
export const getRewindUrl = (spotName, camera, location) => {
  const locationName = location?.toLocaleLowerCase?.();
  const hideRewind = locationName === 'cam rewind' || locationName === 'sessions clip player';
  // If no spotName has been passed then try the camera title just so there's something in there
  // defaults to the first spot associated with the cam id anyway so not really required, defaults to
  // rewind incase there's no title either.
  return hideRewind
    ? undefined
    : `/surf-cams/${slugify(spotName || camera.title || 'rewind')}/${camera._id}`;
};

/**
 * @param {?import('video.js').VideoJsPlayer} camPlayer
 * @param {string} spotId
 * @param {string} spotName
 * @param {import('./').Camera['_id']} camId
 * @param {import('./').Camera['title']} camTitle
 * @param {boolean} isPremium
 * @param {string} rewindUrl
 * @param {boolean} premiumCam
 */
export const addCamRewindButton = (
  camPlayer,
  spotId,
  spotName,
  camId,
  camTitle,
  isPremium,
  rewindUrl,
  premiumCam,
) => {
  if (camPlayer) {
    const { controlBar } = camPlayer;
    const rewindButton = controlBar.addChild('button', {}, controlBar.children().length - 1);
    rewindButton.addClass('vjs-rewind-button');
    rewindButton.el().setAttribute('title', 'Cam Rewind');
    const eventName = 'ontouchstart' in window ? 'touchend' : 'click';
    const handler = () => {
      trackEvent('Clicked Cam Rewind', {
        spotId,
        spotName,
        location: 'cam player',
        camId,
        camName: camTitle,
        premiumCam,
        isMobileView: window.innerWidth < theme.breakpoints.values.tabletLg,
        platform: 'web',
      });
      setTimeout(() => window.location.assign(rewindUrl), 300);
    };
    rewindButton.on(eventName, handler);
    // Remove the event listener when the component is unmounted
    camPlayer.one('dispose', () => {
      rewindButton.off(eventName, handler);
    });
  }
};
