import { useState, useEffect, useCallback } from 'react';

// video.js media error codes here https://docs.videojs.com/mediaerror#.errorTypes
const MANIFEST_ERROR_CODES = [2, 3, 4, 5];

const noErrorState = {
  isDown: false,
  message: '',
  altMessage: '',
  subMessage: '',
};

/**
 * @param {?import('./').CamPlayer} camPlayer
 * @param {import('./').Camera['stillUrl']} stillUrl
 * @param {import('./').Camera['streamUrl']} streamUrl
 * @param {import('./CamPlayerV2').CamPlayerProps['rewindClip']} rewindClip
 * @param {() => void} setupPlayer
 * @param {boolean} showVideoJsError
 */
export const useCamError = (
  camPlayer,
  setupPlayer,
  location,
  setIsPlayerFullscreen,
  showVideoJsError = false,
  retryDelay = 1000,
) => {
  /** @type {[import('./').NetworkError, (networkError: import('./').NetworkError) => void]} */
  const [networkError, setNetworkError] = useState(noErrorState);

  // We don't need the not found count since we use the callback version
  // of the setState command to use/update it
  // eslint-disable-next-line no-unused-vars
  const [_, setNotFoundCount] = useState(0);

  const handleError = useCallback(() => {
    const error = camPlayer?.error();
    if (!error?.code) {
      setIsPlayerFullscreen(false);
      setNetworkError({
        isDown: true,
        message: `We're sorry to report that this camera is down.`,
        subMessage: null,
        altMessage: 'Camera Down',
      });
    }
    /* istanbul ignore else */
    if (error?.code) {
      if (error.code === 2 && location === 'Cam Rewind') {
        setIsPlayerFullscreen(false);
        setNetworkError({
          isDown: true,
          message: `Unfortunately, we are having an issue loading this Cam Rewind.
              Please try downloading the clip to view this Rewind.`,
          subMessage: showVideoJsError ? `(Error code: ${error.code})` : null,
          altMessage: 'Camera Down',
        });
        // if chunklist sends a 404, wait 1 second and resume play
      } else if (error.message.includes('404') || MANIFEST_ERROR_CODES.includes(error.code)) {
        setNotFoundCount((count) => {
          /* istanbul ignore else */
          if (count <= 10) {
            setNetworkError({
              isDown: true,
              message: 'Refreshing camera stream',
            });
            setTimeout(() => {
              setupPlayer();
            }, retryDelay);
          } else {
            setIsPlayerFullscreen(false);
            // update message after 10 tries
            setNetworkError({
              isDown: true,
              message: `We're sorry to report that this camera is down.`,
              subMessage: showVideoJsError ? `(Error code: ${error.code})` : null,
              altMessage: 'Camera Down',
            });

            /*
            Note: we won't call noticeError for these errors because they aren't actionable by us.
            The camera team already has a process for monitoring these errors and fixing them.
            We also send all cam player errors to New Relic as page actions in `useCamMetrics`
            and these can be viewed in New Relic under the Dashboard "Cam Player Web Errors"
            */
          }
          return count + 1;
        });
      } else {
        setIsPlayerFullscreen(false);
        setNetworkError({
          isDown: true,
          message: `We're sorry to report that this camera is down.`,
          subMessage: showVideoJsError ? `(Error code: ${error.code})` : null,
          altMessage: 'Camera Down',
        });
      }
    }
  }, [camPlayer, setIsPlayerFullscreen, location, showVideoJsError, retryDelay, setupPlayer]);

  useEffect(() => {
    if (camPlayer?.error()) {
      handleError();
    } else {
      setNetworkError(noErrorState);
    }
    camPlayer?.on('error', handleError);
    camPlayer?.one('dispose', () => {
      setNetworkError(noErrorState);
      camPlayer?.off('error', handleError);
    });
    camPlayer?.on('playing', () => {
      setNetworkError(noErrorState);
    });
    return () => {
      camPlayer?.off('error', handleError);
    };
  }, [camPlayer, handleError]);
  return { networkError };
};
