import { useEffect, useRef, useState } from 'react';
import { getWindow } from '@surfline/web-common';

const window = getWindow();

/**
 * @description Use New Relic PageActions to gather data on cam palyer set up time,
 * streaming bitrate, and buffering times.
 *
 * @param {Object} props
 * @property {?import('./').CamPlayer} camPlayer
 * @property {import('./').Camera} camera
 */
export const useCamMetrics = ({
  camPlayer,
  camera,
  isHighlightsPlayer,
  isRewindClipsPlayer = false,
}) => {
  const camPlayerRef = useRef(null);
  const retryCount = useRef(0);
  const [startTime, setStartTime] = useState(0);

  // used for tracking initial player setup
  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  useEffect(() => {
    /**
     * @description Only proceed to add the listeners for player performance if and when we
     * have both camPlayer and camera, and only add them once.
     */
    if (
      !(typeof camera === 'object' && camera !== null) ||
      typeof window !== 'object' ||
      !window?.newrelic || // note: this NR check prevents from firing on localhost
      typeof camPlayer !== 'object' ||
      !camPlayer?.on ||
      camPlayerRef.current === camPlayer
    ) {
      return;
    }

    camPlayerRef.current = camPlayer;

    let buffering = false;
    let bufferStart;
    let bufferReason;

    const eventData = {
      cam_id: camera._id,
      cam_title: camera.title,
      streamUrl: !isRewindClipsPlayer ? camera.streamUrl : undefined,
      isPrerecorded: camera.isPrerecorded,
      isRewindClipsPlayer,
      isHighlightsPlayer,
    };

    const handleReady = () => {
      const elapsedTime = new Date().getTime() - startTime;
      window.newrelic.addPageAction('CamPlayer_setuptime', {
        setupTime: elapsedTime,
        retryCount: retryCount.current,
        ...eventData,
      });
    };

    const handleFirstFrame = () => {
      const elapsedTime = new Date().getTime() - startTime;
      window.newrelic.addPageAction('CamPlayer_firstframe', {
        loadTime: elapsedTime,
        retryCount: retryCount.current,
        ...eventData,
      });
    };

    const handleBufferStart = (e) => {
      if (buffering) return;
      buffering = true;
      bufferStart = Date.now();
      bufferReason = e.type;
      window.newrelic.addPageAction('CamPlayer_bufferstart', {
        reason: bufferReason,
        retryCount: retryCount.current,
        quality: camPlayer.getVideoPlaybackQuality(),
        ...eventData,
      });
    };

    const handleBufferEnd = () => {
      if (!buffering) return;
      buffering = false;
      window.newrelic.addPageAction('CamPlayer_bufferend', {
        reason: bufferReason,
        bufferTime: Date.now() - bufferStart,
        retryCount: retryCount.current,
        quality: camPlayer.getVideoPlaybackQuality(),
        ...eventData,
      });
    };

    const handleError = () => {
      const error = camPlayer.error();
      window.newrelic.addPageAction('CamPlayer_error', {
        code: error?.code,
        message: error?.message,
        retryCount: retryCount.current,
        ...eventData,
      });

      retryCount.current += 1;
    };

    camPlayer.on('ready', handleReady);
    camPlayer.on('loadedmetadata', handleFirstFrame);
    camPlayer.on('waiting', handleBufferStart);
    camPlayer.on('playing', handleBufferEnd);
    camPlayer.on('error', handleError);

    camPlayer.one('dispose', () => {
      camPlayer.off('ready', handleReady);
      camPlayer.off('loadedmetadata', handleFirstFrame);
      camPlayer.off('waiting', handleBufferStart);
      camPlayer.off('playing', handleBufferEnd);
      camPlayer.off('error', handleError);
    });
  }, [camPlayer, camera, isHighlightsPlayer, startTime, isRewindClipsPlayer]);
};
