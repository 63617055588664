import { useCallback, useEffect, useState } from 'react';

export const usePlayerReady = (camPlayer) => {
  const [playerReady, setPlayerReady] = useState(false);

  const handleReady = useCallback(() => setPlayerReady(true), []);

  useEffect(() => {
    camPlayer?.on('loadedmetadata', handleReady);
    camPlayer?.one('dispose', () => {
      camPlayer?.off('loadedmetadata', handleReady);
    });
  }, [camPlayer, handleReady, playerReady, setPlayerReady]);

  return playerReady;
};
