import React from 'react';
import PropTypes from 'prop-types';

const CamOverlay = ({ children = null, backgroundImage = null, shouldLoad }) => (
  <div
    style={shouldLoad ? { backgroundImage } : {}}
    className="quiver-cam-overlay"
    data-testid="cam-overlay"
  >
    {children}
  </div>
);

CamOverlay.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  shouldLoad: PropTypes.bool,
};

CamOverlay.defaultProps = {
  backgroundImage: null,
  children: null,
  shouldLoad: false,
};

export default CamOverlay;
