/* eslint-disable import/prefer-default-export */

export const getParsedCameraTitle = (camTitle?: string): string => {
  // This is to remove 'SOCAL - ' from camera name
  const cameraTitle = camTitle || '';
  const parsedCameraTitle =
    cameraTitle.indexOf('- ') !== -1 ? cameraTitle.split('- ')[1] : cameraTitle;

  return parsedCameraTitle;
};
