import { useEffect, useState } from 'react';

const PREMIUM_TIMEOUT = 600; // 10 minute premium timeout

/**
 *
 * @param {?import('.').CamPlayer} camPlayer
 * @param {import('./CamPlayerV2').CamPlayerProps['isMultiCam']} isMultiCam
 * @param {import('./CamPlayerV2').CamPlayerProps['isPrimaryCam']} isPrimaryCam
 * @param {import('./CamPlayerV2').CamPlayerProps['timeoutMultiCams']} timeoutMultiCams
 * @param {() => void} pausePlayer
 * @param {(isTimeout: boolean) => void} trackPause
 */
export const useCamTimeout = (
  camPlayer,
  enableCamTimeout,
  isMultiCam,
  isPrimaryCam,
  timeoutMultiCams,
  pausePlayer,
  trackPause,
) => {
  const [showTimeout, setShowTimeout] = useState(false);

  useEffect(() => {
    let timeElapsed = 0;
    let currentTime = 0;

    const handleTimeUpdate = () => {
      const playerPosition = Math.round(camPlayer?.currentTime());

      /**
       * Because this Event is fired multiple times (<=10) a second,
       * this conditional is required for tracking total seconds played.
       */
      if (playerPosition !== currentTime) {
        currentTime = playerPosition;
        timeElapsed += 1;
      }

      if (timeElapsed >= PREMIUM_TIMEOUT) {
        if (!isMultiCam) {
          setShowTimeout(true);
          pausePlayer();
        } else {
          trackPause(true);
          if (isPrimaryCam && timeoutMultiCams) {
            timeoutMultiCams();
          }
        }
        timeElapsed = 0;
      }
    };

    if (enableCamTimeout) {
      camPlayer?.on('timeupdate', handleTimeUpdate);
      camPlayer?.one('dispose', () => {
        camPlayer?.off('timeupdate', handleTimeUpdate);
      });
    }
  }, [
    camPlayer,
    enableCamTimeout,
    isMultiCam,
    isPrimaryCam,
    pausePlayer,
    timeoutMultiCams,
    trackPause,
  ]);

  return [showTimeout, setShowTimeout];
};
