export { default } from './CamPlayerV2';

/**
 * @typedef {Object} CameraStatus
 * @property {string} message
 * @property {boolean} isDown
 * @property {string} [altMessage]
 * @property {string} [subMessage]
 */

/**
 * @typedef {Object} NetworkError
 * @property {string} message
 * @property {boolean} isDown
 * @property {string} [altMessage]
 * @property {string} [subMessage]
 */

/**
 * @typedef {Object} Camera
 * @property {CameraStatus} status
 * @property {string} title
 * @property {string} _id
 * @property {string} alias
 * @property {string} control
 * @property {string} rewindBaseUrl
 * @property {string} rewindClip
 * @property {string} stillUrl
 * @property {string} streamUrl
 * @property {boolean} isPremium
 * @property {boolean} isPrerecorded
 * @property {string} lastPrerecordedClipEndTimeUTC
 * @property {string} lastPrerecordedClipStartTimeUTC
 * @property {boolean} nighttime
 * @property {{url: string, thumbUrl: string, gifUrl: string, _id: string}} [highlights]
 */

/**
 * @typedef {Object} Playlist
 * @property {string} description
 * @property {string} mediaid
 * @property {string} file
 * @property {string} image
 * @property {string} preload
 * @property {string} title
 * @property {{file: string, label: string, kind: string}[]} tracks
 * @property {[{file: string, label: string, type: string, default: boolean}]} sources
 * @property {{file: string, label: string, type: string, default: boolean}[]} allSources


/** @typedef {{
 *   (event: string, callback: () => void): this;
 *   (event: 'error', callback: (error: {message: string, code: number, type: string}) => void): this;
 *   (event: 'time', callback: (event: {duration: number, position: number, viewable: number}) => void): this;
 * }} CamPlayerEventListener

/**
 * @typedef {Object} CamPlayer
 * @property {() => void} play
 * @property {() => void} pause
 * @property {() => void} stop
 * @property {(playlist: string | Playlist | Playlist[]) => void} load
 * @property {CamPlayerEventListener} on
 * @property {(
 *  event: string,
 *  callback: (
 *    error: { code: number, message: string, type: string }) => void
 *  ) => void
 * } on
 * @property {(
 *  img: string,
 *  tooltip: string,
 *  callback: () => void,
 *  id: string,
 *  btnClass: string
 * ) => void} addButton
 * @see {@link https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference|JWPlayer}
 */
