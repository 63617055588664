import { useEffect, useState } from 'react';

/**
 * @param {object} props
 * @param {?import('video.js').VideoJsPlayer} props.camPlayer
 * @param {(isStreaming: boolean) => void} [props.onToggleStreaming]
 * @param {(showTimeout: boolean) => void} props.setShowTimeout
 * @param {() => void} props.setupPlayer
 * @param {string} props.streamUrl
 * @param {() => void} props.trackPause
 * @param {() => void} props.trackPlay
 */
export const useCamPlayback = ({
  camPlayer,
  setupPlayer,
  streamUrl,
  setShowTimeout,
  onToggleStreaming,
  trackPlay,
  trackPause,
}) => {
  const [isStreaming, setStreaming] = useState(false);

  useEffect(() => {
    camPlayer?.on('play', () => {
      setShowTimeout(false);
      setStreaming(true);
      trackPlay();
    });
    camPlayer?.on('pause', () => {
      setStreaming(false);
      trackPause();
    });
    camPlayer?.on('ended', () => {
      setStreaming(false);
    });
    camPlayer?.one('dispose', () => {
      setStreaming(false);
      camPlayer?.off('play');
      camPlayer?.off('pause');
      camPlayer?.off('ended');
    });
    // We only want to renew these hooks when we have a new cam player
    // since it will add a new callback to the cam player every single time
    // `on()` is called.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camPlayer]);

  // Reset the player if the stream URL changes
  useEffect(() => {
    if (streamUrl) {
      setShowTimeout(false);
      setStreaming(false);
      setupPlayer();
    }
  }, [streamUrl, setupPlayer, setShowTimeout]);

  useEffect(() => {
    /* istanbul ignore else */
    if (onToggleStreaming) {
      onToggleStreaming(isStreaming);
    }
  }, [isStreaming, onToggleStreaming]);

  return isStreaming;
};
