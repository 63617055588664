import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { Stack, Switch, Typography } from '@mui/material';
import { canUseDOM, getWindow, trackEvent } from '@surfline/web-common';

import CamPlayerOverlayControls from 'components/CamPlayerOverlayControls/CamPlayerOverlayControls';
import { CameraInsightsIcon } from 'components/Icons/CameraInsightsIcon';
import useCheckCameraSupportsSmartRewinds from 'hooks/useCheckCameraSupportsSmartRewinds';
import useIsVisible from 'hooks/useIsVisible';
import { useUserPermissionStatus } from 'selectors/user';
import { getParsedCameraTitle } from 'utils/camera';
import useCamAdRunning from 'utils/useGlobalAdRunningState';
import { getSpotDetails } from 'selectors/spot';
import { useAppSelector } from 'stores/hooks';
import writeDebugLog from 'utils/writeDebugLog';
import CamTimeout from './CamTimeout';
import CamPlaceholder from './CamPlaceholder';
import CamError from '../CamError';
import CamUpsell from './CamUpsell';
import CountDownTimer from './CountDownTimer';
import AspectRatioContent from './AspectRatioContent';
import { cameraPropType } from './camerasPropTypes';
import { useCamReady } from './useCamReady';
import { ALT, SUB, MSG, messageConfig, getRewindUrl } from './utils';
import { useCamPlayer } from './useCamPlayer';
import { useCamMetrics } from './useCamMetrics';
import { useCamPlaybackControls } from './useCamPlaybackControls';
import { useCamPlayback } from './useCamPlayback';
import { useCamPlayerAds } from './useCamPlayerAds';
import { useCamError } from './useCamError';
import { useCamTimeout } from './useCamTimeout';
import { useControlledPlayback } from './useControlledPlayback';
import useCamControls from './useCamControls';
import { useTrackCamPlayback } from './useTrackCamPlayback';
import { useTrackClickedSubscribe } from './useTrackClickedSubscribe';
import { useCamHighlights } from './useCamHighlights';
import usePauseOffscreenPlayer from './usePauseOffscreenPlayer';
import { useCamFullscreen, useFullScreenButtonOverride } from './useCamFullscreen';
import { PlayerSkins, AutoplayOptions } from './constants';
import { HighlightsPill, LivePill } from './CamPlayerSVGs';
import useLatestRewind from './useLatestRewind';
import { usePlayerReady } from './usePlayerReady';

import styles from './CamPlayer.module.scss';

const window = getWindow();

/**
 * @param {boolean} showTimeout
 * @param {boolean} isPrerecorded
 * @param {boolean} isPermitted
 */
const camPlayerClassName = (
  showTimeout,
  isPrerecorded,
  isPermitted,
  adRunning,
  isPlayerFullscreen,
  isErrorVisible,
) =>
  classNames({
    'quiver-cam-player': true,
    'quiver-cam-player--layoutV2': true,
    'quiver-cam-player--timeout': showTimeout,
    'quiver-cam-player--prerecorded': isPrerecorded,
    'quiver-cam-player--ad-not-running': !adRunning,
    'quiver-cam-player--free': !isPermitted,
    'quiver-cam-player--is-fullscreen': isPlayerFullscreen,
    'quiver-cam-player--is-not-fullscreen': !isPlayerFullscreen,
    'quiver-cam-player--hidden': isErrorVisible,
  });

/**
 * @param {boolean} playbackRateControls
 */
const camPlayerSettingsClassName = (playbackRateControls) =>
  classNames({
    'quiver-cam-player__player': true,
    'quiver-cam-player__player--show-settings': !!playbackRateControls,
  });

/**
 * @typedef{Object} AdSettingsProps
 * @property {string} [adVastTagUrl]
 * @property {() => void} [onAdStarted]
 * @property {(preRollPlaying: boolean) => void} [onTogglePreRoll]
 * @property {(midRollPlaying: boolean) => void} [onToggleMidRoll]
 * @property {number} [upsellDuration]
 * @property {React.ReactNode} [MidrollCTA]
 *
 *
 * @typedef {Object.<string, string>} SegmentProperties
 *
 * @typedef{Object} CamPlayerProps
 * @property {string} [autoplay]
 * @property {string} [aspectRatio]
 * @property {import('./').Camera} camera
 * @property {React.ReactNode} [camHostAd]
 * @property {boolean} [camReady]
 * @property {boolean} [controlledPlayback]
 * @property {boolean} [isVideoAdPluginsError]
 * @property {boolean} [handleCamReady]
 * @property {boolean} [isClip]
 * @property {boolean} [isMultiCam]
 * @property {boolean} [isPersistentCam]
 * @property {boolean} [isPlaying]
 * @property {boolean} [isPermitted]
 * @property {boolean} [isPrimaryCam]
 * @property {boolean} [loopHighlights]
 * @property {ALT | SUB | MSG} [messageType=MSG]
 * @property {(isStreaming: boolean) => void} [onToggleStreaming]
 * @property {boolean} [playbackRateControls]
 * @property {string} playerId
 * @property {{ start: string | number, end: string | number }} [prerecordedTimeRange]
 * @property {SegmentProperties} [segmentProperties]
 * @property {(isReady: boolean) => void} [setCamReady]
 * @property {boolean} [showOverlays]
 * @property {string} [spotId]
 * @property {string} [spotName]
 * @property {() => void} [timeoutMultiCams]
 * @property {boolean} [isFavorite]
 * @property {string} [location]
 * @property {any} [cssModules]
 * @property {React.ReactNode} [CamErrorComponent=CamError]
 * @property {AdSettingsProps} [adSettings]
 * @property {boolean} [latestRewind]
 * @property {boolean} [dontTrack]
 * @property {boolean} [playInBackground]
 * @property {PlayerSkins} [playerSkin]
 * @property {boolean} [showRewindLink]
 * @property {boolean} [showInsightsSmartRewindsIcon]
 * @property {(isVisible: boolean) => void} [onErrorVisibleChangeCallback]
 * @property {(isVisible: boolean) => void} [onTimeoutVisibleChangeCallback]

 *
 * @param {CamPlayerProps} props
 */
const CamPlayer = ({
  CamErrorComponent,
  adSettings,
  aspectRatio,
  autoplay,
  camHostAd,
  camReady,
  camera,
  controlledPlayback,
  cssModules,
  dontTrack,
  enableCamTimeout,
  handleCamReady,
  isClip,
  isFavorite,
  isMultiCam,
  isPersistentCam,
  isPlaying,
  isPermitted,
  isPrimaryCam,
  isVideoAdPluginsError,
  latestRewind,
  location,
  loopHighlights,
  messageType,
  onToggleStreaming,
  pauseWhenNotVisible,
  playInBackground,
  playbackRateControls,
  playerId,
  playerSkin,
  prerecordedTimeRange,
  segmentProperties,
  setCamReady,
  showOverlays,
  spotId,
  spotName,
  stopStream,
  showLivePill,
  timeoutMultiCams,
  showRewindLink,
  multiCamViewActive,
  playerHandle,
  showInsightsSmartRewindsIcon,
  showPlayerOverlayControls,
}) => {
  const spot = useAppSelector(getSpotDetails);

  const { streamUrl, stillUrl, isPrerecorded, status, highlights, alias, stillUrlFull } = camera;

  const supportsSmartRewinds = useCheckCameraSupportsSmartRewinds(camera, spot);
  const [, setCamAdRunning] = useCamAdRunning();

  const highlightsClipUrl = highlights?.url;

  const highlightsPlayerRef = useRef();
  const mainCamPlayerRef = useRef();
  const playerEventTriggerRef = useRef(null);
  const [camContainerElement, setCamContainerElement] = useState();

  const [mainCamVideoElement, setMainCamVideoElement] = useState();
  const [highlightsVideoElement, setHighlightsVideoElement] = useState();

  const isVisible = useIsVisible(camContainerElement);
  const [shouldLoad, setShouldLoad] = useState(isVisible || playInBackground);

  useEffect(() => {
    if (isVisible && !shouldLoad) {
      setShouldLoad(true);
    }
  }, [isVisible, shouldLoad]);
  const latestRewindUrl = useLatestRewind(alias, latestRewind, isVisible);

  const rewindUrl = showRewindLink && getRewindUrl(spotName, camera, location);

  const parsedCameraTitle = getParsedCameraTitle(camera.title);

  const prerecordedStart =
    prerecordedTimeRange.start && format(new Date(prerecordedTimeRange.start), 'hh:mm');
  const prerecordedEnd =
    prerecordedTimeRange.end && format(new Date(prerecordedTimeRange.end), 'hh:mma');

  const isLive = camera.streamUrl?.indexOf('.mp4') === -1 && !latestRewind;

  const isThumbnail = playerSkin === PlayerSkins.THUMBNAIL;

  const { hasAdFreeCamPermissions } = useUserPermissionStatus();

  const videoType = useMemo(() => {
    switch (location?.toLocaleLowerCase()) {
      case 'spot report cam':
      case 'multi cam':
        return 'live';
      case 'cam rewind':
        return 'rewinds';
      case 'sessions clip player':
        return 'sessions';
      default:
        return undefined;
    }
  }, [location]);

  const { setupPlayer, camPlayer } = useCamPlayer({
    aspectRatio,
    autoplay,
    camera,
    isPersistentCam,
    isPermitted,
    isPrerecorded,
    playbackRateControls,
    playerId,
    playerSkin,
    rewindUrl,
    shouldLoad,
    spotId,
    spotName,
    stillUrl,
    streamUrl: latestRewind ? latestRewindUrl : streamUrl,
    videoElement: mainCamVideoElement,
  });

  useCamMetrics({
    camPlayer,
    camera,
  });

  const { resumePlayer, pausePlayer } = useCamPlaybackControls(camPlayer);
  const { trackPlay, trackPause } = useTrackCamPlayback(
    camera,
    location,
    spotName,
    spotId,
    isFavorite,
    videoType,
    dontTrack,
    supportsSmartRewinds,
  );

  const { trackClickedSubscribeCTA } = useTrackClickedSubscribe(camera, location, spotName, spotId);

  const [showTimeout, setShowTimeout] = useCamTimeout(
    camPlayer,
    enableCamTimeout,
    isMultiCam,
    isPrimaryCam,
    timeoutMultiCams,
    pausePlayer,
    trackPause,
  );

  /* Ad Blockers can prevent camPlayer?.ads from functioning correctly without actually blocking the script
    and `isVideoAdPluginsError` ends up being false in that case. This causes internal video player errors further
    upstream so we can use the existence of of `camPlayer.ads` to force the ads into error fallback mode. */
  const forceBuiltInFallbackAd = useMemo(
    () => isVideoAdPluginsError || !camPlayer?.ads,
    [camPlayer?.ads, isVideoAdPluginsError],
  );

  const { adRunning, fallbackAdCountdownTime, nextAdCountdownTime, showUpsell, upsellDuration } =
    useCamPlayerAds({
      adSettings,
      camPlayer,
      forceBuiltInFallbackAd,
      isPersistentCam,
      hasAdFreeCamPermissions,
      pausePlayer,
      resumePlayer,
      setShowTimeout,
      stillUrl: camera.stillUrl,
      streamUrl,
    });

  const isStreaming = useCamPlayback({
    camPlayer,
    onToggleStreaming,
    setShowTimeout,
    setupPlayer,
    streamUrl,
    trackPause,
    trackPlay,
  });

  usePauseOffscreenPlayer({
    camPlayer,
    isRecording: !isLive,
    isStreaming,
    isVisible,
    pauseWhenNotVisible,
    stopStream,
  });

  const { controlsVisibility } = useCamControls(
    playerEventTriggerRef.current,
    camPlayer,
    isThumbnail,
  );

  useControlledPlayback(camPlayer, controlledPlayback, isPlaying, resumePlayer, pausePlayer);

  useImperativeHandle(
    playerHandle,
    () => ({
      play() {
        resumePlayer();
      },
      pause() {
        pausePlayer();
      },
      toggleNativeFullscreen() {
        if (camPlayer) {
          if (!camPlayer.isFullscreen()) {
            camPlayer.requestFullscreen();
          } else {
            camPlayer.exitFullscreen();
          }
        }
      },
      async requestNativePictureInPicture() {
        const videoElement = camPlayer?.tech()?.el();
        if (
          videoElement &&
          window.document.pictureInPictureEnabled &&
          !videoElement.disablePictureInPicture
        ) {
          try {
            await videoElement.requestPictureInPicture();
          } catch (error) {
            writeDebugLog('Error requesting Picture-in-Picture mode:', error);
          }
        }
      },
      exitNativePictureInPicture() {
        if (window.document.pictureInPictureElement) {
          window.document.exitPictureInPicture?.();
        }
      },
      getPlayer() {
        return camPlayer;
      },
    }),
    [camPlayer, resumePlayer, pausePlayer],
  );

  // This sets the global camAdRunning state to the current adRunning state when the
  // adRunning state changes. This happens immediately upon the state change and makes the variable
  // accessible globally so we can use it in the CamPlayerKbygRewinds component.
  useEffect(() => {
    setCamAdRunning(adRunning);
  }, [adRunning]);

  const { isPlayerFullscreen, toggleFullScreen, setIsPlayerFullscreen } = useCamFullscreen(
    { current: camContainerElement },
    camPlayer?.play,
  );

  const { networkError } = useCamError(
    camPlayer,
    setupPlayer,
    location,
    setIsPlayerFullscreen,
    !!CamErrorComponent,
  );

  const isDown = status.isDown || networkError.isDown || (latestRewind && !latestRewindUrl);

  const messageData = messageConfig(camera.status, isClip, messageType, networkError);

  const {
    camPlayer: camPlayerHighlights,
    highlightsCountdownTime,
    setShowHighlights,
    showHighlights,
    skipHighlights,
  } = useCamHighlights({
    aspectRatio,
    autoplay: isVisible ? 'enabled' : false,
    camera,
    dontTrack,
    highlightsClipUrl,
    isFavorite,
    isPersistentCam,
    isPermitted,
    isPrerecorded,
    isVisible: isVisible && !stopStream,
    location,
    loop: loopHighlights,
    pauseWhenNotVisible,
    playbackRateControls,
    playerId: `${playerId}--highlights`,
    playerSkin,
    rewindUrl,
    shouldLoad,
    spotId,
    spotName,
    stillUrl,
    videoElement: highlightsVideoElement,
    segmentProperties,
  });

  const { controlsVisibility: controlsVisibilityHighlights } = useCamControls(
    playerEventTriggerRef.current,
    camPlayerHighlights,
    isThumbnail,
  );

  const playerReady = usePlayerReady(camPlayer, isDown);
  const playerReadyHighlights = usePlayerReady(camPlayerHighlights, isDown);

  useCamReady(camPlayer, handleCamReady, setCamReady, camReady, autoplay, isPermitted, isDown);

  useFullScreenButtonOverride({
    camRef: highlightsPlayerRef,
    playerReady: playerReadyHighlights,
    toggleFullScreen,
  });

  useFullScreenButtonOverride({
    camRef: mainCamPlayerRef,
    playerReady,
    toggleFullScreen,
  });

  const isNextAdCountdownLabelVisible = useMemo(
    () => adSettings && nextAdCountdownTime > 0 && !adRunning && !showUpsell,
    [adRunning, adSettings, nextAdCountdownTime, showUpsell],
  );

  const isCamResumingCountdownLabelVisible = useMemo(
    () => adSettings && fallbackAdCountdownTime > 0 && adRunning && !showUpsell,
    [adRunning, adSettings, fallbackAdCountdownTime, showUpsell],
  );

  const isErrorVisible = useMemo(() => isDown && !isThumbnail, [isDown, isThumbnail]);

  const isPreRecorded = useMemo(
    () => isPrerecorded && prerecordedStart && !isLive && !showHighlights,
    [isPrerecorded, prerecordedStart, isLive, showHighlights],
  );

  const isSmartRewindsInsightsIconVisible = useMemo(
    () => showInsightsSmartRewindsIcon && supportsSmartRewinds && isMultiCam && multiCamViewActive,
    [showInsightsSmartRewindsIcon, supportsSmartRewinds, isMultiCam, multiCamViewActive],
  );
  const isLivePillVisible = useMemo(
    () =>
      (isLive &&
        !showHighlights &&
        !isPrerecorded &&
        controlsVisibility.staticControlsVisible &&
        isStreaming &&
        !(loopHighlights && !isNil(highlightsClipUrl))) ||
      showLivePill,
    [
      controlsVisibility.staticControlsVisible,
      highlightsClipUrl,
      isLive,
      isPrerecorded,
      isStreaming,
      loopHighlights,
      showHighlights,
      showLivePill,
    ],
  );

  const isHighlightsToggleVisible = useMemo(
    () =>
      !isNil(highlightsClipUrl) &&
      loopHighlights &&
      isPermitted &&
      (showHighlights || controlsVisibility.staticControlsVisible),
    [
      controlsVisibility.staticControlsVisible,
      highlightsClipUrl,
      isPermitted,
      loopHighlights,
      showHighlights,
    ],
  );

  const isHighlightsCountdownVisible = useMemo(
    () => !isNil(highlightsClipUrl) && loopHighlights && isPermitted && showHighlights,
    [highlightsClipUrl, isPermitted, loopHighlights, showHighlights],
  );

  const isHighlightsPillVisible = useMemo(
    () =>
      showHighlights &&
      controlsVisibilityHighlights.staticControlsVisible &&
      !(loopHighlights && !isNil(highlightsClipUrl)),
    [
      controlsVisibilityHighlights.staticControlsVisible,
      highlightsClipUrl,
      loopHighlights,
      showHighlights,
    ],
  );

  const isHighlightsSkipVisible = useMemo(
    () => showHighlights && isThumbnail,
    [showHighlights, isThumbnail],
  );

  const isPlaceholderVisible = useMemo(
    () => isThumbnail && (isDown || !isStreaming),
    [isDown, isStreaming, isThumbnail],
  );

  const overlayAdStyles = useMemo(
    () => ({ display: controlsVisibility.staticControlsVisible ? 'block' : 'none' }),
    [controlsVisibility.staticControlsVisible],
  );

  const camTitleStyles = useMemo(
    () => ({ display: controlsVisibility.staticControlsVisible ? 'block' : 'none' }),
    [controlsVisibility.staticControlsVisible],
  );

  const livePlayerStyles = useMemo(
    () => ({ display: showHighlights ? 'none' : 'block' }),
    [showHighlights],
  );

  const highlightsPlayerStyles = useMemo(
    () => ({ display: showHighlights ? 'block' : 'none' }),
    [showHighlights],
  );

  const clickHighlightsToggle = useCallback(() => {
    trackEvent('Clicked Toggle', {
      ...segmentProperties,
      toggleType: 'highlights',
      toggleStart: showHighlights ? 'highlights' : 'live',
      toggleEnd: showHighlights ? 'live' : 'highlights',
    });
    if (showHighlights) {
      resumePlayer();
    } else {
      pausePlayer();
    }
    setShowHighlights(!showHighlights);
  }, [pausePlayer, resumePlayer, segmentProperties, setShowHighlights, showHighlights]);

  const renderCamPills = useCallback(() => {
    if (!isSmartRewindsInsightsIconVisible && !isLivePillVisible && !isHighlightsPillVisible)
      return null;

    return (
      <div className="quiver-cam-player__player__pillWrapper">
        {isSmartRewindsInsightsIconVisible && (
          <div
            className="quiver-cam-player__player__smartRewindsInsights"
            data-testid="sl-smartRewinds-lozenge"
          >
            <CameraInsightsIcon />
          </div>
        )}
        {isLivePillVisible && (
          <div className="quiver-cam-player__player__live" data-testid="sl-live-pill">
            <LivePill />
          </div>
        )}
        {isHighlightsPillVisible && (
          <div className="quiver-cam-player__player__live" data-testid="sl-highlights-pill">
            <HighlightsPill />
          </div>
        )}
      </div>
    );
  }, [isSmartRewindsInsightsIconVisible, isLivePillVisible, isHighlightsPillVisible]);

  return (
    <div
      className={styles.camPlayer}
      data-testid={`cam-player-wrapper-${adRunning ? '-ad-running' : ''}`}
      ref={playerEventTriggerRef}
    >
      {showOverlays && !adRunning && !isErrorVisible ? (
        <div className="quiver-cam-player__player__overlays">
          <div
            className={classNames('quiver-cam-player__player__host-ad', styles.camhostAd)}
            style={overlayAdStyles}
          >
            {camHostAd}
          </div>
          {isMultiCam ? (
            <div className="quiver-cam-player__player__overlays__cam-title" style={camTitleStyles}>
              {parsedCameraTitle}
            </div>
          ) : null}
        </div>
      ) : null}
      {isErrorVisible ? (
        <CamErrorComponent
          messageData={status.isDown ? messageData.camStatusMessage : messageData.camDownMessage}
          rewindUrl={rewindUrl}
          camStillUrl={stillUrl}
        />
      ) : null}
      <div
        ref={setCamContainerElement}
        className={camPlayerClassName(
          showTimeout,
          isPrerecorded,
          isPermitted,
          adRunning,
          isPlayerFullscreen,
          isErrorVisible,
        )}
        data-testid="cam-player"
      >
        <div className={camPlayerSettingsClassName(playbackRateControls)}>
          <div>
            {isPreRecorded ? (
              <div className="quiver-cam-player__player__recorded-earlier">
                Recorded Earlier&nbsp;&nbsp;|&nbsp;&nbsp;
                {prerecordedStart} - {prerecordedEnd}
              </div>
            ) : null}
            {isHighlightsToggleVisible ? (
              <Stack
                alignItems="center"
                className={styles.highlightsToggleContainer}
                data-testid="highlights-toggle"
                direction="row"
                spacing={0}
              >
                <Typography
                  className={styles.highlightsToggleLabel}
                  component="span"
                  variant="body1"
                >
                  Highlights
                </Typography>
                <Switch
                  checked={!showHighlights}
                  className={styles.highlightsToggle}
                  data-testid="highlights-toggle-switch"
                  onClick={clickHighlightsToggle}
                />
                <Typography
                  className={styles.highlightsToggleLabel}
                  component="span"
                  variant="body1"
                >
                  Live Cam
                </Typography>
              </Stack>
            ) : null}
            {renderCamPills()}
            {isHighlightsSkipVisible ? (
              <button
                type="button"
                className="quiver-cam-player__player__highlights-skip"
                onClick={skipHighlights}
              >
                Skip
              </button>
            ) : null}
            {isThumbnail && (
              <button
                type="button"
                className={`quiver-cam-player__player__fullscreen quiver-cam-player__player__fullscreen__fullscreen-${
                  !isPlayerFullscreen ? 'on' : 'off'
                }`}
                aria-label="Fullscreen"
                onClick={toggleFullScreen}
              />
            )}
          </div>
          {isPlaceholderVisible && (
            <AspectRatioContent>
              <CamPlaceholder stillUrl={stillUrlFull} isDown={isDown} shouldLoad={shouldLoad} />
            </AspectRatioContent>
          )}
          {showPlayerOverlayControls && !isErrorVisible && !showTimeout && (
            <CamPlayerOverlayControls
              className="quiver-cam-player__player__overlayControls"
              autoplay={autoplay}
              controlsVisible={controlsVisibility.primaryControlsVisible}
              playing={!camPlayer?.paused()}
              pause={() => pausePlayer()}
              play={() => resumePlayer()}
            />
          )}
          <AspectRatioContent>
            {showTimeout ? (
              <CamTimeout
                stillUrl={camera.stillUrl}
                onClick={resumePlayer}
                shouldLoad={shouldLoad}
              />
            ) : null}
            {adSettings && showUpsell ? (
              <div className={styles.upsellWrapper}>
                {adSettings?.MidrollCTA ? (
                  <adSettings.MidrollCTA />
                ) : (
                  <CamUpsell
                    href="/upgrade"
                    stillUrl={camera.stillUrl}
                    cssModules={cssModules}
                    onClick={trackClickedSubscribeCTA}
                    shouldLoad={shouldLoad}
                  />
                )}

                <div
                  className={
                    cssModules['quiver-cam-player__upsell-countdown'] ||
                    'quiver-cam-player__upsell-countdown'
                  }
                >
                  Next ad will start in <CountDownTimer countTime={upsellDuration} /> seconds
                </div>
              </div>
            ) : null}
            {isNextAdCountdownLabelVisible ? (
              <div
                className={
                  cssModules['quiver-cam-player__countdown'] || 'quiver-cam-player__countdown'
                }
              >
                <Typography variant="callout1" component="span">
                  Next ad: <strong>{nextAdCountdownTime}</strong>
                </Typography>
              </div>
            ) : null}
            {isHighlightsCountdownVisible ? (
              <div
                className={
                  cssModules['quiver-cam-player__countdown'] || 'quiver-cam-player__countdown'
                }
              >
                <Typography variant="callout1" component="span">
                  <strong>{highlightsCountdownTime}s</strong>
                </Typography>
              </div>
            ) : null}
            {isCamResumingCountdownLabelVisible ? (
              <div
                className={
                  cssModules['quiver-cam-player__countdown'] || 'quiver-cam-player__countdown'
                }
              >
                <Typography variant="callout1" component="span">
                  Live cam will start in: <strong>{fallbackAdCountdownTime}</strong>
                </Typography>
              </div>
            ) : null}
            <div
              ref={mainCamPlayerRef}
              style={livePlayerStyles}
              className="quiver-cam-player__player-container"
            >
              <div ref={setMainCamVideoElement} id={playerId} />
            </div>
            {highlightsClipUrl ? (
              <div
                ref={highlightsPlayerRef}
                style={highlightsPlayerStyles}
                className="quiver-cam-player__player-container--highlights"
              >
                <div ref={setHighlightsVideoElement} id={`${playerId}--highlights`} />
              </div>
            ) : null}
          </AspectRatioContent>
        </div>
      </div>
    </div>
  );
};

CamPlayer.defaultProps = {
  CamErrorComponent: CamError,
  adSettings: {
    adVastTagUrl: null,
    MidrollCTA: null,
  },
  aspectRatio: '16:9',
  autoplay: null,
  camHostAd: null,
  camReady: false,
  controlledPlayback: false,
  cssModules: {},
  enableCamTimeout: true,
  handleCamReady: false,
  isClip: false,
  isFavorite: false,
  isMultiCam: false,
  isPersistentCam: false,
  isPlaying: false,
  isPermitted: true,
  isPrimaryCam: false,
  isVideoAdPluginsError: false,
  location: canUseDOM && window.location.pathname,
  loopHighlights: false,
  messageType: MSG,
  onToggleStreaming: null,
  pauseWhenNotVisible: false,
  playInBackground: false,
  playbackRateControls: false,
  playerSkin: 'default',
  prerecordedTimeRange: {
    start: null,
    end: null,
  },
  segmentProperties: {},
  setCamReady: null,
  showOverlays: true,
  spotId: null,
  spotName: null,
  stopStream: false,
  showLivePill: false,
  timeoutMultiCams: null,
  showRewindLink: true,
  multiCamViewActive: false,
  playerHandle: null,
};

CamPlayer.propTypes = {
  CamErrorComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  adSettings: PropTypes.shape({
    adVastTagUrl: PropTypes.string,
    MidrollCTA: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  }),
  aspectRatio: PropTypes.string,
  autoplay: PropTypes.oneOf([
    AutoplayOptions.AUTOPLAY_DISABLED,
    AutoplayOptions.AUTOPLAY_ENABLED,
    null,
  ]),
  camHostAd: PropTypes.node,
  camReady: PropTypes.bool,
  camera: cameraPropType.isRequired,
  controlledPlayback: PropTypes.bool,
  cssModules: PropTypes.shape(),
  enableCamTimeout: PropTypes.bool,
  handleCamReady: PropTypes.bool,
  isClip: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isMultiCam: PropTypes.bool,
  isPersistentCam: PropTypes.bool,
  isPlaying: PropTypes.bool,
  isPermitted: PropTypes.bool,
  isPrimaryCam: PropTypes.bool,
  isVideoAdPluginsError: PropTypes.bool,
  location: PropTypes.string,
  loopHighlights: PropTypes.bool,
  messageType: PropTypes.oneOf([ALT, SUB, MSG]),
  onToggleStreaming: PropTypes.func,
  pauseWhenNotVisible: PropTypes.bool,
  playInBackground: PropTypes.bool,
  playbackRateControls: PropTypes.bool,
  playerId: PropTypes.string.isRequired,
  playerSkin: PropTypes.oneOf(['default', 'minimal', 'thumbnail', 'none']),
  prerecordedTimeRange: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
  }),
  segmentProperties: PropTypes.objectOf(PropTypes.string),
  setCamReady: PropTypes.func,
  showOverlays: PropTypes.bool,
  spotId: PropTypes.string,
  spotName: PropTypes.string,
  stopStream: PropTypes.bool,
  timeoutMultiCams: PropTypes.func,
  showRewindLink: PropTypes.bool,
  showLivePill: PropTypes.bool,
  multiCamViewActive: PropTypes.bool,
  playerHandle: PropTypes.shape({
    current: PropTypes.shape({
      play: PropTypes.func,
      pause: PropTypes.func,
    }),
  }),
};

export default React.memo(CamPlayer);
