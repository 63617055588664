import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';

import CamOverlay from '../CamOverlay';

import styles from './CamTimeout.module.scss';

const CamTimeout = ({ stillUrl, onClick, text, isMultiCam, shouldLoad }) => (
  <div className={classNames(styles.camTimeout, 'quiver-cam-timeout')} data-testid="cam-timeout">
    {!isMultiCam && <CamOverlay backgroundImage={`url(${stillUrl})`} shouldLoad={shouldLoad} />}
    <div className={classNames(styles.message, 'quiver-cam-timeout__message')}>
      <Typography className="cam-timeout-message-text" component="h3" mb={2} variant="title3">
        {text.message}
      </Typography>
      <Button
        className={styles.button}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={onClick}
        variant="primary"
      >
        {text.linkText}
      </Button>
    </div>
  </div>
);

CamTimeout.propTypes = {
  isMultiCam: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  stillUrl: PropTypes.string,
  text: PropTypes.shape({
    message: PropTypes.string,
    linkText: PropTypes.string,
  }),
  shouldLoad: PropTypes.bool,
};

CamTimeout.defaultProps = {
  isMultiCam: false,
  stillUrl: '',
  text: {
    message: 'Are you still watching?',
    linkText: 'Continue watching',
  },
  shouldLoad: false,
};

export default CamTimeout;
