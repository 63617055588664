import { useCallback } from 'react';

/**
 * @param {?import('video.js').VideoJsPlayer} camPlayer
 */
export const useCamPlaybackControls = (camPlayer) => {
  const pausePlayer = useCallback(async () => {
    if (camPlayer?.pause) {
      try {
        await camPlayer.pause();
      } catch (e) {
        // ignore these errors
        console.log('pausePlayer exception, e=', e);
      }
    }
  }, [camPlayer]);

  const resumePlayer = useCallback(async () => {
    if (camPlayer?.play) {
      try {
        await camPlayer.play();
      } catch (e) {
        // ignore these errors
        console.log('resumePlayer exception, e=', e);
      }
    }
  }, [camPlayer]);

  const restartPlayer = useCallback(async () => {
    if (camPlayer?.currentTime) {
      try {
        await camPlayer.currentTime(0);
        await resumePlayer();
      } catch (e) {
        // ignore these errors
        console.log('restartPlayer exception, e=', e);
      }
    }
  }, [camPlayer, resumePlayer]);

  return { pausePlayer, restartPlayer, resumePlayer };
};
