import { useCallback, useEffect, useState } from 'react';
import { getWindow } from '@surfline/web-common';

const usePauseOffscreenPlayer = ({
  isVisible,
  isStreaming,
  camPlayer,
  stopStream = false,
  isRecording = true,
  pauseWhenNotVisible,
}) => {
  const [position, setPosition] = useState(0);

  const playPauseCamPlayer = useCallback(
    async (shouldPlay = true) => {
      try {
        if (shouldPlay) {
          await camPlayer?.play();
        } else {
          await camPlayer?.pause();
        }
      } catch (e) {
        // play and pause can throw exceptions with play and pause that don't reflect actionable errors.
        // We'll silently catch them here to avoid having them bubble up to New Relic.
        console.log('playPauseCamPlayer exception, e=', e);
      }
    },
    [camPlayer],
  );

  useEffect(() => {
    if (camPlayer && pauseWhenNotVisible) {
      if (isVisible && !isStreaming && !stopStream) {
        if (getWindow()?.document?.hidden === true) {
          // Important: the browser will throw an exception if we try to play when the tab is invisible.
          // The error is "The play() request was interrupted because video-only background media was paused to save power"
          return;
        }
        if (isRecording) camPlayer.currentTime(Math.min(0, position - 5)); // set the seek position back a bit in the hope that initially it'll be loading cached data
        playPauseCamPlayer(true);
      } else if ((!isVisible || stopStream) && isStreaming) {
        if (isRecording) setPosition(camPlayer.currentTime());
        playPauseCamPlayer(false);
      }
    }
  }, [
    isVisible,
    camPlayer,
    position,
    isStreaming,
    stopStream,
    pauseWhenNotVisible,
    isRecording,
    playPauseCamPlayer,
  ]);

  return isVisible;
};

export default usePauseOffscreenPlayer;
