import React from 'react';

const RewindV2 = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22C12 16.4772 16.4772 12 22 12C27.5228 12 32 16.4772 32 22ZM22 30.25C26.5563 30.25 30.25 26.5563 30.25 22C30.25 17.4437 26.5563 13.75 22 13.75C17.4437 13.75 13.75 17.4437 13.75 22C13.75 26.5563 17.4437 30.25 22 30.25Z"
      fill="white"
    />
    <path d="M17.25 22L21.4924 18.8182V25.1818L17.25 22Z" fill="white" />
    <path d="M21.7578 22L26.0002 18.8182V25.1818L21.7578 22Z" fill="white" />
  </svg>
);

export default RewindV2;
