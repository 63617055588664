import { memo } from 'react';

const RewindButton = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.5" width="80" height="80" rx="40" fill="#171717" />
    <path
      d="M28.0641 28.2754C20.9956 34.8235 20.6089 45.8244 27.2004 52.8464C33.7919 59.8685 44.8655 60.2526 51.9339 53.7045C59.0024 47.1563 59.3891 36.1554 52.7977 29.1334C49.0484 25.1392 43.8491 23.2927 38.7614 23.6479M41.249 19.625L36.7918 23.8472L41.249 28.375"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default memo(RewindButton);
